const pinterest = {
	orderConfirmation: (data) => {
		const pinterestData = {
			value: parseFloat(data.subTotal),
			order_quantity: 0,
			currency: data.currencyCode.toUpperCase(),
			line_items: [],
		}
		for (const lineItem of data.lineItems) {
			pinterestData.order_quantity += parseInt(lineItem.quantity)
			pinterestData.line_items.push({
				product_name: lineItem.description,
				product_id: lineItem.itemCode,
				product_price: parseFloat(lineItem.price),
				product_quantity: parseInt(lineItem.quantity),
			})
		}
		firePintrk('checkout', pinterestData)
	},

	enrollConfirmation: (data) => {
		const pinterestData = {
			value: parseFloat(data.subTotal),
			order_quantity: 1,
			currency: data.currencyCode.toUpperCase(),
		}
		firePintrk('signup', pinterestData)
	},

	trackLead: (data) => {
		const pinterestData = {
			lead_type: data.leadType,
		}
		firePintrk('lead', pinterestData)
	},

	productBrowse: (data) => {
		firePintrk('pagevisit', data)
	},

	cartAdd: (data) => {
		const pinterestData = {
			value: 0,
			order_quantity: 0,
			currency: data.currencyCode.toUpperCase(),
			line_items: [],
		}
		for (const cartItem of data.cartItems) {
			pinterestData.value += cartItem.subtotalRaw
			pinterestData.order_quantity += cartItem.quantity
			pinterestData.line_items.push({
				product_name: cartItem.details.title,
				product_id: cartItem.details.itemCode,
				product_price: cartItem.price,
				product_quantity: cartItem.quantity,
			})
		}
		firePintrk('addtocart', pinterestData)
	},
}

const firePintrk = (event, payload, action = 'track') => {
	pintrk(action, event, payload)
}

export default pinterest
