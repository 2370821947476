import { pinterest } from './tracking/index.js'

let trackingLoaded = false
let trackingEventQueue = []
let loadedTrackers = []

const fireTrackersForEvent = (event, data) => {
	for (const tracker of loadedTrackers) {
		if (tracker[event]) {
			tracker[event](data)
		}
	}
}

const trackingAddEventQueue = (eventData) => {
	trackingEventQueue.push(eventData)
}

const trackingFlushQueue = () => {
	for (const { eventType, data } of trackingEventQueue) {
		fireTrackersForEvent(eventType, data)
	}
	trackingEventQueue = []
}

const handleTrackingEvent = ({ detail: { eventType, data } }) => {
	trackingAddEventQueue({ eventType, data })
	if (trackingLoaded) {
		trackingFlushQueue()
	}
}

;(function (d) {
	if (document.addEventListener) document.addEventListener('ltkAsyncListener', d)
	else {
		e = document.documentElement
		e.ltkAsyncProperty = 0
		e.attachEvent('onpropertychange', function (e) {
			if (e.propertyName == 'ltkAsyncProperty') {
				d()
			}
		})
	}
})(function () {
	trackingLoaded = true
	_ltk.SCA.CaptureEmail('email')
	trackingFlushQueue()
})

window.addEventListener('tracking', handleTrackingEvent)

const PINTEREST_ENABLED = import.meta.env.VITE_PINTEREST_ENABLED
const PINTEREST_CODE = import.meta.env.VITE_PINTEREST_CODE
if (PINTEREST_ENABLED) {
	loadedTrackers.push(pinterest)
	!(function (e) {
		if (!window.pintrk) {
			window.pintrk = function () {
				window.pintrk.queue.push(Array.prototype.slice.call(arguments))
			}
			var n = window.pintrk
			;(n.queue = []), (n.version = '3.0')
			var t = document.createElement('script')
			;(t.async = !0), (t.src = e)
			var r = document.getElementsByTagName('script')[0]
			r.parentNode.insertBefore(t, r)
		}
	})('https://s.pinimg.com/ct/core.js')
	pintrk('load', PINTEREST_CODE)
	pintrk('page')
}
